<template>
  <div id="addedit-main" class="alone-possess">
    <div class="addedit-title">{{ Title }}</div>
    <div class="addedit-bor"></div>
    <div class="addedit-cont">
      <n-form
        :model="model"
        ref="formRef"
        :rules="rules"
        label-placement="left"
        :label-width="120"
      >
        <n-form-item path="school_name" label="学校名称：">
          <div class="school-text">{{ model.school_name }}</div>
        </n-form-item>

        <n-form-item path="system_name" label="系统名称：" style="width: 400px">
          <n-input v-model:value="model.system_name" maxlength="14" show-count/>
        </n-form-item>

        <n-form-item path="route" label="客户端图标 : ">
          <!-- 头像上传 -->
          <UPheadPortrait v-if="isUrl == false"
            text="限上传PNG、JPG格式图片 建议100*100 大小不超过2M"
            @imagedata="imagedata"
            size="2"
            removeUrl="/backend/web/upload/delete"
            :imaData="model.file_url"
          />

          <UPheadPortrait v-else
            text="限上传PNG、JPG格式图片 建议100*100 大小不超过2M"
            @imagedata="imagedata"
            size="2"
            removeUrl="/backend/web/upload/delete"
            :imaData="model.file_url"
          />

        </n-form-item>

        <n-form-item label="地址：">
          <!-- 省 -->
          <n-select
            class="school-select"
            placeholder="选址省份"
            v-model:value="model.provinceID"
            :options="provinceData"
            :value="model.provinceID"
            @update:value="provinceValue"
          />

          <!-- 市 -->
          <n-select
            class="school-select"
            placeholder="选择城市"
            v-model:value="model.cityID"
            :options="cityData"
            :value="model.cityID"
            @update:value="cityValue"
          />

          <!-- 区 -->
          <n-select
              class="school-select"
              placeholder="选择区县"
              v-model:value="model.areaID"
              :options="areaData"
              :value="model.areaID"
              @update:value="areaValue"
            />

          <n-input v-model:value="model.address" placeholder="请输入详细地址" />
        </n-form-item>

        <n-form-item path="remark" label="类型：">
          <!-- 类型： -->
          <n-select
              class="school-select"
              placeholder="选择学校类型"
              v-model:value="model.school_type_id"
              :options="TypeData"
              :value="model.school_type_id"
              @update:value="TypeValue"
            />
        </n-form-item>

        <n-form-item path="two_domain" label="独立域名：">
          <div class="school-text">{{ model.two_domain }}</div>
        </n-form-item>

        <n-form-item path="username" label="管理员账号：">
          <div class="school-text">{{ model.username }}</div>
        </n-form-item>

        <n-row :gutter="[0, 24]">
          <n-col :span="24">
            <div class="addedit-btns">
              <n-button type="tertiary" @click="handleBack">
                返回
              </n-button>
              <n-button
                color="#744DFE"
                @click="handleValidateButtonClick"
                round
              >
                确定</n-button
              >
            </div>
          </n-col>
        </n-row>
      </n-form>
      <PageLoading :loading="loading" />
      <pre>
      <!-- {{ JSON.stringify(model, 0, 2) }} -->
      </pre>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GetSchoolDetail,
  PostSchoolUpdate,
  PostUploadRegion,
  PostWebSchooTtypeIndex
} from "@/api/school.js"; // 详情 编辑 地址 类型
import UPheadPortrait from "@/components/UPheadPortrait/index.vue";
import { useRoute, useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";

import env from '@/settings/env.js';

const { SUCCESS } = resStatusEnum;
const loading = ref(false);

export default defineComponent({
  // 挂载组件
  components: {
    UPheadPortrait,
    PageLoading,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    let otherId = route.query.id;
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const isUrl = ref(false)
    const model = ref({
      id: null, // 学校ID
      system_name: null, // 系统名称
      school_name: null, // 学校名称
      address: null, // 详细地址
      two_domain: null, // 二级域名
      file_url: [{ name: "cc.png", url: "", status: "finished" }], // 图标url数组 //
      file_name: null, // 图标文件名
      username: null, // 登录账号
      provinceID: null, // 省ID
      cityID: null, // 市ID
      areaID: null, // 区ID
      badge:null,  // 传给后端的头像
    });

    const provinceData = ref([]); // 省
    const cityData = ref([]); // 市
    const areaData = ref([]); // 区
    const TypeData = ref([])  // 学校类型
    // 数据初始化
    function InitData() {

      // 获取学校类型
      PostWebSchooTtypeIndex().then((res) => {
        if (res.code === SUCCESS) {
          let Datas = res.data.list
          for(var i=0;i<Datas.length;i++){
            let data = {
              "label":Datas[i].title,
              "value":Datas[i].id
            }
            TypeData.value.push(data)
          }
        }
      })
      .catch((reject) => {});

      loading.value = true;
      GetSchoolDetail({ "School[id]": otherId })
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // 城市有值时 初始化数据
            if(res.data.city!=''){
              regionInitData(res.data.province,'city');
            }
            if(res.data.district!=''){
              regionInitData(res.data.city,'area');
            }
            
            model.value.id = res.data.id; // 学校ID
            model.value.school_name = res.data.school_name; // 学校名称
            model.value.school_type_id = res.data.school_type_id; // 学校类型
            model.value.two_domain = res.data.two_domain; // 二级域名
            model.value.file_url =  [{ name: "cc.png", url: res.data.badge || `${env.publicPath}img/common/logo.png?v=${env.resourceVersion}`, status: "finished" }]// 图标URL res.data.badge
            // [{ name: "cc.png", url: res.data.badge,status: "finished" }]; 
            model.value.username = res.data.username; // 登录账号
            model.value.address = res.data.address; // 详细地址
            model.value.provinceID = res.data.province; //省份ID
            model.value.cityID = res.data.city; // 城市ID
            model.value.areaID = res.data.district; // 区县ID
            
            // console.log("badge",res.data.badge)
            // 系统名称为空时赋值
            if (res.data.system_name == "") {
              model.value.system_name = res.data.school_name;
            } else {
              model.value.system_name = res.data.system_name; // 系统名称
            }

            // 如果头像有值则
            if(res.data.badge){
              isUrl.value = true
            }
            isUrl.value = true
          }
        })
        .catch((reject) => {});
    }

    InitData();

    // 省市区数据初始化
    function regionInitData(id,style) {
      loading.value = true;
      // 菜单详情
      PostUploadRegion({ parent_id: id })
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            let data = Object.keys(res.data).map((value) => ({
                        label: res.data[value],
                        value,
                      }));
            if(style=='province'){
              // 省份请求
              provinceData.value = data
            }else if(style=='city'){
              // 城市请求
              cityData.value = data
            }else if(style=='area'){
              // 区县请求
              areaData.value = data
            }
          }
        })
        .catch((reject) => {});
    }

    regionInitData(0,'province');

     // 返回事件
    const handleBack = () => {
      router.push('/school/index/index')
    };
    // 父子组件传值
    const imagedata = (params) => {
      for (var i in params) {
        model.value.file_url = params[i].url;
        model.value.file_name = params[i].name;
        model.value.badge = params[i].url;
      }
      // console.log("父子组件传值",model.value.badge)
    };

    return {
      formRef,
      rPasswordFormItemRef,
      model,
      loading,
      Title: "学校编辑",
      provinceData,
      cityData,
      areaData,
      imagedata,
      handleBack,
      TypeData,
      isUrl,
      rules: {
        name: [
          {
            required: false,
            validator(rule, value) {
              if (!value) {
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
      },
      // 省份选择选择时触发
      provinceValue(value, option) {
        // console.log("省份选择选择时触发", value, option);
        model.value.provinceID = value
        model.value.cityID = ''
        model.value.areaID = ''
        regionInitData(value,'city');
      },

      cityValue(value, option) {
        // console.log("城市选择选择时触发", value, option);
        model.value.cityID = value
        regionInitData(value,'area');
      },

      // 区县选择选择时触发
      areaValue(value, option) {
        // console.log("区县选择选择时触发", value, option);
        model.value.areaID = value
      },

      // 学校类型选择选择时触发
      TypeValue(value, option) {
        // console.log("区县选择选择时触发", value, option);
        model.value.school_type_id = value
      },

      // 提交事件
      handleValidateButtonClick(e) {
        formRef.value.validate((errors) => {
          
          // 处理头像url地址问题
          if(model.value.badge !=null){
            let index=model.value.badge.lastIndexOf("?");
            if(index>0){
              model.value.badge = model.value.badge.substring(0,index)
            }
          }
          
          if (model.value.system_name == "") {
            message.error("系统名称不可为空");
            return
          } else if (model.value.badge == ""){
            message.error("客户端图标不可为空");
            return
          }else{
            // console.log("提交事件")
            loading.value = true;
            let data = {
              "School[id]": model.value.id, // 上级ID
              "School[system_name]": model.value.system_name, // 系统名称
              "School[province]": model.value.provinceID, // 省ID
              "School[city]": model.value.cityID, // 市ID
              "School[district]": model.value.areaID, // 区ID
              "School[address]": model.value.address, // 详细地址
              "School[badge]": model.value.badge, // 图标url
              "School[school_type_id]": model.value.school_type_id, // 图标url
            };
            PostSchoolUpdate(data).then((res) => {
              if (res.code === SUCCESS) {
                loading.value = false;
                message.success(res.msg);
                setTimeout(function () {
                  window.location.href = "/school/index/index";
                }, 1000);
              }
            });
          }
        });
      },
    };
  },
});
</script>

<style scoped>
#addedit-main {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
#addedit-main .addedit-title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
.addedit-bor {
  height: 1px;
  border-bottom: 1px solid #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
}
.n-form-item-feedback-wrapper {
  padding-left: 120px;
}
.addedit-cont {
  width: 800px;
  padding-top: 45px;
}
.addedit-btns {
  justify-content: flex-end;
  padding-top: 60px;
}
.addedit-btns button {
  border-radius: 2px !important;
  width: 120px;
}
.addedit-btns .n-button--tertiary-type.n-button--medium-type {
  border: 1px solid #ccc;
  margin-right: 30px;
}
.n-button__content a {
  color: #000;
  text-decoration: none;
}
.school-select{
  width:200px;
  margin-right:10px;
}
</style>